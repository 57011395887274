.chat {
  position: relative;
  overflow-x: auto;

  .messages {
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 20px;
    padding-bottom: 50px;

    .examples {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;

        p {
          text-align: center;
        }
      }

      .cards {
        display: flex;
        gap: 15px;

        .card {
          background: var(--card-bg);
          border-radius: 10px;
          padding: 10px;
          width: 15rem;
          cursor: pointer;
          transition: background 0.25s ease, transform 0.1s ease;
          border: 1px solid var(--card-border);

          &:hover {
            background: var(--card-bg-hover);
          }

          &:active {
            transform: translateY(1px);
          }
        }
      }
    }

    .messageContainer {
      display: flex;

      &.loading {
        justify-content: center;

        p {
          color: var(--placeholder);
        }
      }

      &.from-user {
        justify-content: flex-end;
        .message {
          background: var(--accent-1);
        }
      }

      &.from-bot {
        justify-content: flex-start;
        .message {
          background: var(--accent-2);
        }
      }

      .message {
        padding: 10px;
        max-width: 55%;
        border-radius: 12px;
      }
    }
  }

  .input {
    position: fixed;
    bottom: 0;
    padding: 10px;
    left: 50%;
    display: flex;
    gap: 10px;
    width: 50%;
    background: var(--chat-bg);
    z-index: 99;

    input {
      background: none;
      border: var(--border);
      border-radius: 100px;
      padding: 5px 20px;
      width: 100%;

      &:focus {
        outline: none;
        border-color: var(--accent-1);
      }
    }

    button {
      background: var(--accent-1);
      border: none;
      padding: 5px 20px;
      border-radius: 100px;
      cursor: pointer;
      transition: transform 0.1s ease;

      &:disabled {
        pointer-events: none;
      }

      &:active {
        transform: translateY(1px);
      }

      &.clear {
        background: var(--accent-2);
      }
    }
  }
}
