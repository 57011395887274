@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --intro-bg: #191919;
  --chat-bg: #222222;
  --card-bg: #3a3a3a;
  --card-border: #474747;
  --card-bg-hover: #464646;

  --fg: #fff;

  --accent-1: #d64416;
  --accent-2: #606060;

  --placeholder: #525252;
  --border: 1px solid #717171;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
  color: var(--fg);
}

body {
  height: 100vh;
}

.App {
  height: 100vh;
  background: var(--intro-bg);
  display: flex;

  .col {
    width: 100%;
    height: 100%;

    &.intro {
      background: var(--intro-bg);
      display: grid;
      place-items: center;

      .wrapper {
        max-width: 50%;

        .title {
          margin-bottom: 0.5rem;
          font-size: 2rem;
          display: flex;
          align-items: center;
          gap: 10px;

          img {
            height: 4rem;
          }
        }

        .description {
          margin-bottom: 2rem;
        }

        .cta {
          background: var(--accent-1);
          border: none;
          padding: 10px 20px;
          border-radius: 7px;
          cursor: pointer;
          transition: transform 0.1s ease;

          &:active {
            transform: translateY(1px);
          }
        }
      }
    }

    &.chat {
      background: var(--chat-bg);
    }
  }
}

@media screen and (max-width: 1560px) {
  .examples {
    top: 150px !important;
    .cards {
      flex-direction: column;
      font-size: 14px;
      top: 0px !important;
    }
  }
}

@media screen and (max-width: 1145px) {
  .App {
    .intro {
      .wrapper {
        max-width: 70% !important;
      }
    }
  }
}

@media screen and (max-width: 950px) {
  .App {
    flex-direction: column;

    .intro {
      height: 50%;
      padding: 20px;

      .wrapper {
        max-width: 100% !important;

        .title {
          font-size: 1.7rem !important;

          img {
            height: 3.3rem !important;
          }
        }
      }
    }

    .chat {
      .input {
        position: fixed !important;
        width: 100%;
        left: 0;
      }

      .messages {
        .messageContainer {
          .message {
            max-width: 80% !important;
          }
        }
      }
    }
  }
}
